import React, { Component } from "react";

export default class DesignImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        style="enable-background:new 0 0 500 500;"
      >
        <g id="Background_Complete">
          <g>
            <rect
              x="416.779"
              y="398.494"
              style="fill:#EBEBEB;"
              width="33.122"
              height="0.25"
            />
            <rect
              x="322.527"
              y="401.208"
              style="fill:#EBEBEB;"
              width="8.693"
              height="0.25"
            />
            <rect
              x="396.586"
              y="389.208"
              style="fill:#EBEBEB;"
              width="19.192"
              height="0.25"
            />
            <rect
              x="52.459"
              y="390.888"
              style="fill:#EBEBEB;"
              width="43.193"
              height="0.25"
            />
            <rect
              x="104.556"
              y="390.888"
              style="fill:#EBEBEB;"
              width="6.333"
              height="0.25"
            />
            <rect
              x="131.471"
              y="395.111"
              style="fill:#EBEBEB;"
              width="93.676"
              height="0.25"
            />
            <path
              style="fill:#EBEBEB;"
              d="M237.014,337.8H43.915c-3.147,0-5.708-2.561-5.708-5.708V60.66c0-3.147,2.561-5.708,5.708-5.708
			h193.099c3.146,0,5.707,2.561,5.707,5.708v271.432C242.721,335.239,240.16,337.8,237.014,337.8z M43.915,55.203
			c-3.01,0-5.458,2.448-5.458,5.458v271.432c0,3.01,2.448,5.458,5.458,5.458h193.099c3.009,0,5.457-2.448,5.457-5.458V60.66
			c0-3.009-2.448-5.458-5.457-5.458H43.915z"
            />
            <path
              style="fill:#EBEBEB;"
              d="M453.31,337.8H260.212c-3.147,0-5.707-2.561-5.707-5.708V60.66c0-3.147,2.561-5.708,5.707-5.708
			H453.31c3.148,0,5.708,2.561,5.708,5.708v271.432C459.019,335.239,456.458,337.8,453.31,337.8z M260.212,55.203
			c-3.009,0-5.457,2.448-5.457,5.458v271.432c0,3.01,2.448,5.458,5.457,5.458H453.31c3.01,0,5.458-2.448,5.458-5.458V60.66
			c0-3.009-2.448-5.458-5.458-5.458H260.212z"
            />
            <rect y="382.398" style="fill:#EBEBEB;" width="500" height="0.25" />
          </g>
          <g>
            <g>
              <rect
                x="291.957"
                y="94.669"
                style="fill:#EBEBEB;"
                width="124.785"
                height="132.891"
              />
              <rect
                x="299.457"
                y="102.169"
                style="fill:#F5F5F5;"
                width="109.786"
                height="117.892"
              />
              <g>
                <polygon
                  style="fill:#FAFAFA;"
                  points="342.55,220.06 375.55,220.06 406.531,102.169 373.531,102.169 				"
                />
                <polygon
                  style="fill:#FAFAFA;"
                  points="358.198,102.169 327.217,220.06 335.217,220.06 366.198,102.169 				"
                />
              </g>
              <g>
                <polygon
                  style="fill:#F5F5F5;"
                  points="418.325,100.922 290.375,100.922 287.675,94.622 421.025,94.622 				"
                />
                <polygon
                  style="fill:#F0F0F0;"
                  points="418.325,104.571 290.375,104.571 287.675,98.271 421.025,98.271 				"
                />
                <polygon
                  style="fill:#E6E6E6;"
                  points="418.325,108.221 290.375,108.221 287.675,101.921 421.025,101.921 				"
                />
                <polygon
                  style="fill:#E0E0E0;"
                  points="418.325,111.87 290.375,111.87 287.675,105.57 421.025,105.57 				"
                />
                <rect
                  x="283.025"
                  y="86.731"
                  style="fill:#E0E0E0;"
                  width="142.65"
                  height="8.401"
                />
                <path
                  style="fill:#E0E0E0;"
                  d="M284.49,171.292L284.49,171.292c-0.802,0-1.451-0.65-1.451-1.451v-48.994
					c0-0.802,0.65-1.451,1.451-1.451l0,0c0.802,0,1.451,0.65,1.451,1.451v48.994C285.941,170.642,285.291,171.292,284.49,171.292z"
                />
                <rect
                  x="283.965"
                  y="93.447"
                  style="fill:#E0E0E0;"
                  width="1.05"
                  height="29.098"
                />
              </g>
            </g>
            <g>
              <g>
                <rect
                  x="88.725"
                  y="92.097"
                  style="fill:#E0E0E0;"
                  width="1.8"
                  height="140.366"
                />
                <rect
                  x="200.775"
                  y="92.097"
                  style="fill:#E0E0E0;"
                  width="1.8"
                  height="140.366"
                />
              </g>
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,100.922 81.675,100.922 78.975,94.622 212.325,94.622 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,109.571 81.675,109.571 78.975,103.271 212.325,103.271 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,118.221 81.675,118.221 78.975,111.921 212.325,111.921 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,126.87 81.675,126.87 78.975,120.57 212.325,120.57 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,135.519 81.675,135.519 78.975,129.219 212.325,129.219 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,144.169 81.675,144.169 78.975,137.869 212.325,137.869 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,152.818 81.675,152.818 78.975,146.518 212.325,146.518 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,161.467 81.675,161.467 78.975,155.167 212.325,155.167 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,170.117 81.675,170.117 78.975,163.817 212.325,163.817 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,178.766 81.675,178.766 78.975,172.466 212.325,172.466 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,187.416 81.675,187.416 78.975,181.116 212.325,181.116 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,196.065 81.675,196.065 78.975,189.765 212.325,189.765 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,204.714 81.675,204.714 78.975,198.415 212.325,198.415 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,213.364 81.675,213.364 78.975,207.064 212.325,207.064 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,222.013 81.675,222.013 78.975,215.713 212.325,215.713 			"
              />
              <polygon
                style="fill:#F5F5F5;"
                points="209.625,230.663 81.675,230.663 78.975,224.363 212.325,224.363 			"
              />
              <rect
                x="78.975"
                y="230.663"
                style="fill:#E0E0E0;"
                width="133.35"
                height="3.6"
              />
              <rect
                x="74.325"
                y="86.731"
                style="fill:#E0E0E0;"
                width="142.65"
                height="8.401"
              />
              <path
                style="fill:#E0E0E0;"
                d="M75.789,171.292L75.789,171.292c-0.802,0-1.451-0.65-1.451-1.451v-48.994
				c0-0.802,0.65-1.451,1.451-1.451l0,0c0.801,0,1.451,0.65,1.451,1.451v48.994C77.241,170.642,76.591,171.292,75.789,171.292z"
              />
              <rect
                x="75.264"
                y="93.447"
                style="fill:#E0E0E0;"
                width="1.05"
                height="29.098"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style="fill:#EBEBEB;"
                d="M89.317,322.377c-0.122,0.136,0.551,1.076-2.188,0.664c-6.748-1.014-18.499-3.748-21.771-10.692
				c-4.672-9.914,9.813-6.295,9.313-11.362c-0.5-5.067-16.981-4.184-18.732-11.618c-1.751-7.433,9.43-3.893,9.578-7.516
				c0.148-3.622-12.732-1.374-15.288-9.393c-2.557-8.019,7.178-2.116,6.059-5.45c-1.119-3.333-12.015-18.416-6.086-20.167
				c5.929-1.751,15.917,13.726,19.771,15.919c3.854,2.193,7.279-10.439,11.873-5.106c4.594,5.333-2.692,23.994,3.167,27.615
				c4.364,2.697,3.132-4.732,8.891-3.346C101.729,283.811,99.097,311.438,89.317,322.377z"
              />
              <path
                style="fill:#F5F5F5;"
                d="M68.488,281.897c2.655,0.222,4.615,0.763,5.832,1.198c-2.378-3.483-4.088-5.27-4.15-5.332
				c-0.193-0.197-0.191-0.513,0.006-0.707c0.196-0.193,0.512-0.19,0.706,0.005c0.048,0.048,3.992,4.112,8.312,12.059
				c2.121-12.788-3.234-23.965-3.29-24.08c-0.091-0.186-0.014-0.411,0.172-0.502c0.185-0.092,0.41-0.014,0.501,0.172
				c0.059,0.12,5.776,12.049,3.184,25.467c0.69,1.316,1.385,2.724,2.072,4.236c1.984,4.367,4.06,9.966,5.701,16.713
				c1.139-2.058,3.75-7.569,3.983-15.263c0.006-0.207,0.179-0.369,0.386-0.363c0.211,0.014,0.37,0.18,0.364,0.387
				c-0.27,8.903-3.682,14.991-4.473,16.289c2.172,9.361,3.474,20.883,2.539,34.456c-0.019,0.276-0.258,0.484-0.533,0.465
				c-0.021-0.001-0.042-0.004-0.062-0.008c-0.244-0.048-0.42-0.27-0.402-0.525c1.239-17.972-1.45-32.417-5.168-43.356
				c-0.944-1.091-3.733-3.893-7.979-4.552c-0.192-0.047-0.345-0.225-0.312-0.429c0.032-0.204,0.224-0.344,0.428-0.312
				c3.357,0.532,5.817,2.273,7.278,3.617c-2.744-7.599-5.938-13.376-8.525-17.331c-0.834-0.376-3.113-1.262-6.631-1.556
				c-0.203-0.026-0.36-0.198-0.342-0.405C68.1,282.033,68.282,281.88,68.488,281.897z"
              />
            </g>
            <g>
              <g>
                <path
                  style="fill:#E0E0E0;"
                  d="M97.964,326.206c5.608,0.107,15.186-5.101,18.947-10.168c3.816-5.141-6.918-1.952-5.816-5.989
					c1.102-4.037,14.336-1.053,16.778-6.836c2.442-5.783-7.106-4.453-6.727-7.409c0.379-2.956,10.271-2.355,13.446-8.501
					c3.176-6.147-5.525-2.703-4.16-5.251c1.366-2.547,12.271-13.27,7.708-15.505c-4.564-2.235-14.788,8.932-18.213,10.179
					c-3.425,1.247-4.226-6.467-8.683-2.778c-4.457,3.69-1.121,19.814-6.368,21.942c-3.908,1.585-2.246-5.798-6.745-3.935
					C90.256,295.217,95.687,326.162,97.964,326.206z"
                />
                <g style="opacity:0.3;">
                  <path
                    style="fill:#E0E0E0;"
                    d="M97.964,326.206c5.608,0.107,15.186-5.101,18.947-10.168c3.816-5.141-6.918-1.952-5.816-5.989
						c1.102-4.037,14.336-1.053,16.778-6.836c2.442-5.783-7.106-4.453-6.727-7.409c0.379-2.956,10.271-2.355,13.446-8.501
						c3.176-6.147-5.525-2.703-4.16-5.251c1.366-2.547,12.271-13.27,7.708-15.505c-4.564-2.235-14.788,8.932-18.213,10.179
						c-3.425,1.247-4.226-6.467-8.683-2.778c-4.457,3.69-1.121,19.814-6.368,21.942c-3.908,1.585-2.246-5.798-6.745-3.935
						C90.256,295.217,95.687,326.162,97.964,326.206z"
                  />
                </g>
              </g>
              <path
                style="fill:#F5F5F5;"
                d="M99.815,319.475c-0.081-0.032-0.154-0.081-0.198-0.164c-0.131-0.249-3.205-6.195-2.516-15.033
				c0.017-0.206,0.19-0.357,0.403-0.345c0.207,0.017,0.361,0.196,0.345,0.403c-0.582,7.462,1.645,12.924,2.27,14.288
				c2.51-6.815,5.519-12.877,8.54-18.066c-0.992-12.042,5.178-21.902,5.241-22c0.111-0.175,0.342-0.227,0.518-0.115
				c0.175,0.111,0.227,0.343,0.115,0.518c-0.059,0.094-5.726,9.159-5.198,20.437c7.187-12.038,14.203-19.032,14.343-19.17
				c0.197-0.192,0.514-0.191,0.707,0.008c0.193,0.196,0.19,0.514-0.007,0.707c-0.118,0.115-5.106,5.099-10.945,13.839
				c3.422-1.272,7.133-1.934,10.602-1.867c0.207,0.004,0.372,0.175,0.368,0.383c-0.004,0.204-0.171,0.367-0.375,0.367
				c-0.002,0-0.005,0-0.007,0c-3.714-0.067-7.711,0.71-11.308,2.202c-2.008,3.078-4.093,6.596-6.102,10.494
				c1.928-1.303,5.672-3.108,10.462-1.842c0.2,0.053,0.32,0.258,0.267,0.458c-0.053,0.2-0.258,0.323-0.459,0.267
				c-6.075-1.602-10.357,2.028-11.058,2.674c-5.499,11.073-10.242,25.072-10.87,41.114c-0.01,0.27-0.232,0.48-0.499,0.48
				c-0.007,0-0.013,0-0.02,0c-0.276-0.011-0.491-0.243-0.48-0.52C94.38,338.136,96.679,328.214,99.815,319.475z"
              />
            </g>
            <path
              style="fill:#F0F0F0;"
              d="M82.193,380.983c-5.353-10.065-8.869-24.608-8.869-30.983c0-5.388,2.527-10.223,6.536-13.609
			h27.411c4.009,3.386,6.536,8.221,6.536,13.609c0,6.375-3.516,20.918-8.869,30.983H82.193z"
            />
          </g>
        </g>
        <g id="Background_Simple" style="display:none;">
          <g style="display:inline;">
            <path
              style="fill:#407BFF;"
              d="M223.365,181.621c-16.753,7.14-53.258-12.19-70.929-16.591
			c-44.588-11.104-96.446,7.628-92.039,69.988c6.025,85.254,131.879,178.944,263.452,134.088
			c66.461-22.658,120.878-85.988,120.749-161.958C444.362,69.096,313.47,64.42,272.34,99.041
			C233.822,131.463,245.446,172.211,223.365,181.621z"
            />
            <path
              style="opacity:0.9;fill:#FFFFFF;"
              d="M223.365,181.621c-16.753,7.14-53.258-12.19-70.929-16.591
			c-44.588-11.104-96.446,7.628-92.039,69.988c6.025,85.254,131.879,178.944,263.452,134.088
			c66.461-22.658,120.878-85.988,120.749-161.958C444.362,69.096,313.47,64.42,272.34,99.041
			C233.822,131.463,245.446,172.211,223.365,181.621z"
            />
          </g>
        </g>
        <g id="Shadow">
          <ellipse
            id="_x3C_Path_x3E__1_"
            style="fill:#F5F5F5;"
            cx="245.178"
            cy="412.391"
            rx="193.889"
            ry="11.323"
          />
        </g>
        <g id="Screen">
          <g>
            <g>
              <path
                style="fill:#407BFF;"
                d="M292.652,119.641h-23.983c-1.571,0-2.999,1.167-3.334,2.726l-0.162,0.751
				c-0.819,3.805-4.307,6.656-8.143,6.656h-22.8c-3.836,0-6.825-2.851-6.978-6.656l-0.03-0.751
				c-0.063-1.558-1.286-2.726-2.857-2.726h-23.983c-7.999,0-15.05,6.484-15.75,14.483l-19.295,220.538
				c-0.7,7.999,5.217,14.483,13.216,14.483h92.271c7.999,0,15.05-6.484,15.75-14.483l19.295-220.538
				C306.568,126.125,300.651,119.641,292.652,119.641z"
              />
              <path
                style="opacity:0.7;fill:#FFFFFF;"
                d="M292.652,119.641h-23.983c-1.571,0-2.999,1.167-3.334,2.726l-0.162,0.751
				c-0.819,3.805-4.307,6.656-8.143,6.656h-22.8c-3.836,0-6.825-2.851-6.978-6.656l-0.03-0.751
				c-0.063-1.558-1.286-2.726-2.857-2.726h-23.983c-7.999,0-15.05,6.484-15.75,14.483l-19.295,220.538
				c-0.7,7.999,5.217,14.483,13.216,14.483h92.271c7.999,0,15.05-6.484,15.75-14.483l19.295-220.538
				C306.568,126.125,300.651,119.641,292.652,119.641z"
              />
            </g>
            <path
              style="fill:#FFFFFF;"
              d="M283.3,221.075h-48.995c-1.778,0-3.092-1.441-2.937-3.219l3.691-42.188
			c0.156-1.778,1.723-3.219,3.5-3.219h48.995c1.778,0,3.093,1.441,2.937,3.219l-3.691,42.188
			C286.645,219.634,285.077,221.075,283.3,221.075z"
            />
            <path
              style="fill:#FFFFFF;"
              d="M266.985,352.995h-81.987c-4.414,0-7.679-3.578-7.293-7.992l0,0
			c0.386-4.414,4.278-7.992,8.691-7.992h81.987c4.414,0,7.679,3.578,7.293,7.992l0,0
			C275.29,349.416,271.399,352.995,266.985,352.995z"
            />
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M273.534,160.852h-75.23c-2.478,0-4.312-2.009-4.095-4.487l0.613-7.01
				c0.217-2.478,2.402-4.487,4.88-4.487h75.231c2.478,0,4.312,2.009,4.095,4.487l-0.613,7.01
				C278.197,158.842,276.012,160.852,273.534,160.852z"
              />
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M288.83,149.125c-0.06,0.69-0.668,1.249-1.358,1.249s-1.2-0.559-1.14-1.249
					c0.06-0.69,0.669-1.249,1.358-1.249C288.38,147.876,288.891,148.435,288.83,149.125z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M288.504,152.859c-0.06,0.69-0.668,1.249-1.358,1.249c-0.69,0-1.2-0.559-1.14-1.249
					c0.06-0.69,0.668-1.249,1.358-1.249C288.054,151.61,288.564,152.169,288.504,152.859z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M288.177,156.594c-0.06,0.69-0.668,1.249-1.358,1.249c-0.69,0-1.2-0.559-1.14-1.249
					c0.06-0.69,0.668-1.249,1.358-1.249C287.727,155.345,288.237,155.904,288.177,156.594z"
                />
              </g>
            </g>
            <path
              style="fill:#FFFFFF;"
              d="M277.21,290.681h-91.534c-1.778,0-3.092-1.441-2.937-3.219l4.213-48.155
			c0.156-1.778,1.723-3.219,3.5-3.219h91.534c1.778,0,3.092,1.441,2.937,3.219l-4.213,48.155
			C280.555,289.24,278.988,290.681,277.21,290.681z"
            />
            <g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M210.859,300.743h-28.496c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h28.496c0.434,0,0.755,0.352,0.717,0.786l0,0
					C211.676,300.391,211.293,300.743,210.859,300.743z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M246.478,300.743h-30.592c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h30.592c0.434,0,0.755,0.352,0.717,0.786l0,0
					C247.295,300.391,246.912,300.743,246.478,300.743z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M201.033,307.687h-19.277c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h19.277c0.434,0,0.755,0.352,0.717,0.786l0,0
					C201.85,307.335,201.467,307.687,201.033,307.687z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M237.637,307.687h-31.578c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h31.578c0.434,0,0.755,0.352,0.717,0.786l0,0
					C238.454,307.335,238.071,307.687,237.637,307.687z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M232.54,314.632h-51.392c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h51.392c0.434,0,0.755,0.352,0.717,0.786l0,0
					C233.357,314.28,232.974,314.632,232.54,314.632z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M240.96,314.632h-3.393c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h3.393c0.434,0,0.755,0.352,0.717,0.786l0,0
					C241.777,314.28,241.394,314.632,240.96,314.632z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M204.535,321.577h-23.994c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h23.994c0.434,0,0.755,0.352,0.717,0.786l0,0
					C205.352,321.224,204.969,321.577,204.535,321.577z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M239.435,321.577h-29.874c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h29.874c0.434,0,0.755,0.352,0.717,0.786l0,0
					C240.252,321.224,239.869,321.577,239.435,321.577z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M209.477,328.521h-29.544c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h29.544c0.434,0,0.755,0.352,0.717,0.786l0,0
					C210.294,328.169,209.911,328.521,209.477,328.521z"
                />
              </g>
              <g>
                <path
                  style="fill:#FFFFFF;"
                  d="M238.304,328.521h-23.8c-0.434,0-0.755-0.352-0.717-0.786l0,0
					c0.038-0.434,0.421-0.786,0.855-0.786h23.8c0.434,0,0.755,0.352,0.717,0.786l0,0
					C239.121,328.169,238.738,328.521,238.304,328.521z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M282.136,217.082c-0.401,0.414-1.037,0.454-1.419,0.089l-40.926-39.059
					c-0.383-0.365-0.367-0.997,0.034-1.412l0,0c0.401-0.414,1.037-0.454,1.419-0.089l40.926,39.059
					C282.553,216.036,282.537,216.668,282.136,217.082L282.136,217.082z"
                />
              </g>
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M236.169,217.066L236.169,217.066c-0.329-0.415-0.234-1.047,0.213-1.412l20.876-17.072
					c0.446-0.365,1.075-0.325,1.404,0.089l0,0c0.329,0.415,0.234,1.047-0.213,1.412l-20.876,17.072
					C237.126,217.521,236.498,217.481,236.169,217.066z"
                />
              </g>
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M263.148,195.003L263.148,195.003c-0.329-0.414-0.234-1.047,0.213-1.412l20.915-17.105
					c0.447-0.365,1.075-0.325,1.404,0.089l0,0c0.329,0.415,0.234,1.047-0.213,1.412l-20.915,17.105
					C264.105,195.458,263.477,195.418,263.148,195.003z"
                />
              </g>
              <g style="opacity:0.7;">
                <path
                  style="fill:#FFFFFF;"
                  d="M282.136,217.082c-0.401,0.414-1.037,0.454-1.419,0.089l-40.926-39.059
					c-0.383-0.365-0.367-0.997,0.034-1.412l0,0c0.401-0.414,1.037-0.454,1.419-0.089l40.926,39.059
					C282.553,216.036,282.537,216.668,282.136,217.082L282.136,217.082z"
                />
              </g>
              <g style="opacity:0.7;">
                <path
                  style="fill:#FFFFFF;"
                  d="M236.169,217.066L236.169,217.066c-0.329-0.415-0.234-1.047,0.213-1.412l20.876-17.072
					c0.446-0.365,1.075-0.325,1.404,0.089l0,0c0.329,0.415,0.234,1.047-0.213,1.412l-20.876,17.072
					C237.126,217.521,236.498,217.481,236.169,217.066z"
                />
              </g>
              <g style="opacity:0.7;">
                <path
                  style="fill:#FFFFFF;"
                  d="M263.148,195.003L263.148,195.003c-0.329-0.414-0.234-1.047,0.213-1.412l20.915-17.105
					c0.447-0.365,1.075-0.325,1.404,0.089l0,0c0.329,0.415,0.234,1.047-0.213,1.412l-20.915,17.105
					C264.105,195.458,263.477,195.418,263.148,195.003z"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Icons">
          <g>
            <path
              style="fill:#407BFF;"
              d="M165.743,114.845H152.79c-2.626,0-4.755,2.129-4.755,4.755v12.953c0,2.626,2.129,4.755,4.755,4.755
			h12.953c2.626,0,4.755-2.129,4.755-4.755V119.6C170.498,116.974,168.369,114.845,165.743,114.845z M159.266,119.442
			c1.955,0,3.006,1.585,3.006,3.54c0,1.955-1.051,3.54-3.006,3.54c-1.955,0-3.006-1.585-3.006-3.54
			C156.26,121.027,157.311,119.442,159.266,119.442z M167.085,132.711h-15.637c-0.13,0-0.249-0.053-0.334-0.139l1.002-2.737
			c0.645-1.762,2.322-2.934,4.198-2.934h5.905c1.876,0,3.553,1.172,4.198,2.934l1.002,2.737
			C167.333,132.658,167.215,132.711,167.085,132.711z"
            />
            <path
              style="opacity:0.7;fill:#FFFFFF;"
              d="M165.743,114.845H152.79c-2.626,0-4.755,2.129-4.755,4.755v12.953
			c0,2.626,2.129,4.755,4.755,4.755h12.953c2.626,0,4.755-2.129,4.755-4.755V119.6C170.498,116.974,168.369,114.845,165.743,114.845
			z M159.266,119.442c1.955,0,3.006,1.585,3.006,3.54c0,1.955-1.051,3.54-3.006,3.54c-1.955,0-3.006-1.585-3.006-3.54
			C156.26,121.027,157.311,119.442,159.266,119.442z M167.085,132.711h-15.637c-0.13,0-0.249-0.053-0.334-0.139l1.002-2.737
			c0.645-1.762,2.322-2.934,4.198-2.934h5.905c1.876,0,3.553,1.172,4.198,2.934l1.002,2.737
			C167.333,132.658,167.215,132.711,167.085,132.711z"
            />
          </g>
          <g>
            <g>
              <path
                style="fill:#407BFF;"
                d="M119.658,168.117h-9.842c-3.538,0-6.406,2.868-6.406,6.406v9.842c0,3.538,2.868,6.406,6.406,6.406
				h9.842c3.538,0,6.406-2.868,6.406-6.406v-9.842C126.065,170.985,123.197,168.117,119.658,168.117z M114.737,184.815
				c0,0-4.043-4.398-4.043-6.631c0-2.233,1.81-4.043,4.043-4.043c2.233,0,4.043,1.81,4.043,4.043
				C118.781,180.417,114.737,184.815,114.737,184.815z"
              />
              <path
                style="fill:#407BFF;"
                d="M114.737,176.339c-1.019,0-1.845,0.826-1.845,1.845c0,1.019,0.826,1.845,1.845,1.845
				c1.019,0,1.845-0.826,1.845-1.845C116.582,177.165,115.756,176.339,114.737,176.339z"
              />
            </g>
            <g style="opacity:0.7;">
              <path
                style="fill:#FFFFFF;"
                d="M119.658,168.117h-9.842c-3.538,0-6.406,2.868-6.406,6.406v9.842c0,3.538,2.868,6.406,6.406,6.406
				h9.842c3.538,0,6.406-2.868,6.406-6.406v-9.842C126.065,170.985,123.197,168.117,119.658,168.117z M114.737,184.815
				c0,0-4.043-4.398-4.043-6.631c0-2.233,1.81-4.043,4.043-4.043c2.233,0,4.043,1.81,4.043,4.043
				C118.781,180.417,114.737,184.815,114.737,184.815z"
              />
              <path
                style="fill:#FFFFFF;"
                d="M114.737,176.339c-1.019,0-1.845,0.826-1.845,1.845c0,1.019,0.826,1.845,1.845,1.845
				c1.019,0,1.845-0.826,1.845-1.845C116.582,177.165,115.756,176.339,114.737,176.339z"
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                style="fill:#407BFF;"
                points="335.698,140.234 342.289,135.967 335.698,131.701 			"
              />
              <path
                style="fill:#407BFF;"
                d="M344.191,124.338h-12.556c-2.956,0-5.352,2.396-5.352,5.351v12.557
				c0,2.955,2.396,5.351,5.352,5.351h12.556c2.955,0,5.352-2.396,5.352-5.351v-12.557
				C349.542,126.733,347.146,124.338,344.191,124.338z M337.913,144.332c-4.62,0-8.365-3.745-8.365-8.365
				c0-4.62,3.745-8.365,8.365-8.365c4.62,0,8.365,3.745,8.365,8.365C346.277,140.587,342.532,144.332,337.913,144.332z"
              />
            </g>
            <g style="opacity:0.7;">
              <polygon
                style="fill:#FFFFFF;"
                points="335.698,140.234 342.289,135.967 335.698,131.701 			"
              />
              <path
                style="fill:#FFFFFF;"
                d="M344.191,124.338h-12.556c-2.956,0-5.352,2.396-5.352,5.351v12.557
				c0,2.955,2.396,5.351,5.352,5.351h12.556c2.955,0,5.352-2.396,5.352-5.351v-12.557
				C349.542,126.733,347.146,124.338,344.191,124.338z M337.913,144.332c-4.62,0-8.365-3.745-8.365-8.365
				c0-4.62,3.745-8.365,8.365-8.365c4.62,0,8.365,3.745,8.365,8.365C346.277,140.587,342.532,144.332,337.913,144.332z"
              />
            </g>
          </g>
        </g>
        <g id="Gears">
          <g>
            <path
              style="fill:#407BFF;"
              d="M356.518,172.511c0-0.26-0.013-0.516-0.036-0.77l1.509-0.838c-0.114-0.726-0.305-1.426-0.561-2.093
			l-1.724,0.029c-0.218-0.469-0.477-0.915-0.772-1.334l0.887-1.479c-0.456-0.563-0.97-1.077-1.533-1.533l-1.479,0.887
			c-0.419-0.295-0.865-0.553-1.334-0.772l0.029-1.724c-0.667-0.257-1.367-0.448-2.093-0.561l-0.838,1.509
			c-0.254-0.022-0.51-0.036-0.77-0.036c-0.26,0-0.516,0.014-0.77,0.036l-0.838-1.509c-0.726,0.114-1.426,0.305-2.093,0.561
			l0.029,1.724c-0.469,0.218-0.915,0.477-1.334,0.772l-1.479-0.887c-0.563,0.456-1.077,0.97-1.533,1.533l0.887,1.479
			c-0.295,0.419-0.554,0.865-0.772,1.334l-1.724-0.029c-0.257,0.667-0.448,1.367-0.561,2.093l1.509,0.838
			c-0.022,0.254-0.036,0.51-0.036,0.77c0,0.26,0.013,0.516,0.036,0.77l-1.509,0.839c0.114,0.726,0.305,1.426,0.561,2.093
			l1.724-0.029c0.218,0.469,0.477,0.915,0.772,1.334l-0.887,1.479c0.456,0.563,0.97,1.077,1.533,1.533l1.479-0.887
			c0.419,0.295,0.865,0.554,1.334,0.772l-0.029,1.724c0.667,0.257,1.367,0.448,2.093,0.561l0.838-1.509
			c0.254,0.022,0.51,0.036,0.77,0.036c0.26,0,0.516-0.014,0.77-0.036l0.838,1.509c0.726-0.114,1.426-0.305,2.093-0.561l-0.029-1.724
			c0.469-0.218,0.915-0.477,1.334-0.772l1.479,0.887c0.563-0.456,1.077-0.97,1.533-1.533l-0.887-1.479
			c0.295-0.419,0.554-0.865,0.772-1.334l1.724,0.029c0.257-0.667,0.448-1.367,0.561-2.093l-1.509-0.838
			C356.504,173.027,356.518,172.77,356.518,172.511z M347.803,178.427c-3.268,0-5.916-2.649-5.916-5.916
			c0-3.268,2.649-5.916,5.916-5.916c3.268,0,5.916,2.649,5.916,5.916C353.719,175.778,351.07,178.427,347.803,178.427z"
            />
            <path
              style="opacity:0.7;fill:#FFFFFF;"
              d="M356.518,172.511c0-0.26-0.013-0.516-0.036-0.77l1.509-0.838
			c-0.114-0.726-0.305-1.426-0.561-2.093l-1.724,0.029c-0.218-0.469-0.477-0.915-0.772-1.334l0.887-1.479
			c-0.456-0.563-0.97-1.077-1.533-1.533l-1.479,0.887c-0.419-0.295-0.865-0.553-1.334-0.772l0.029-1.724
			c-0.667-0.257-1.367-0.448-2.093-0.561l-0.838,1.509c-0.254-0.022-0.51-0.036-0.77-0.036c-0.26,0-0.516,0.014-0.77,0.036
			l-0.838-1.509c-0.726,0.114-1.426,0.305-2.093,0.561l0.029,1.724c-0.469,0.218-0.915,0.477-1.334,0.772l-1.479-0.887
			c-0.563,0.456-1.077,0.97-1.533,1.533l0.887,1.479c-0.295,0.419-0.554,0.865-0.772,1.334l-1.724-0.029
			c-0.257,0.667-0.448,1.367-0.561,2.093l1.509,0.838c-0.022,0.254-0.036,0.51-0.036,0.77c0,0.26,0.013,0.516,0.036,0.77
			l-1.509,0.839c0.114,0.726,0.305,1.426,0.561,2.093l1.724-0.029c0.218,0.469,0.477,0.915,0.772,1.334l-0.887,1.479
			c0.456,0.563,0.97,1.077,1.533,1.533l1.479-0.887c0.419,0.295,0.865,0.554,1.334,0.772l-0.029,1.724
			c0.667,0.257,1.367,0.448,2.093,0.561l0.838-1.509c0.254,0.022,0.51,0.036,0.77,0.036c0.26,0,0.516-0.014,0.77-0.036l0.838,1.509
			c0.726-0.114,1.426-0.305,2.093-0.561l-0.029-1.724c0.469-0.218,0.915-0.477,1.334-0.772l1.479,0.887
			c0.563-0.456,1.077-0.97,1.533-1.533l-0.887-1.479c0.295-0.419,0.554-0.865,0.772-1.334l1.724,0.029
			c0.257-0.667,0.448-1.367,0.561-2.093l-1.509-0.838C356.504,173.027,356.518,172.77,356.518,172.511z M347.803,178.427
			c-3.268,0-5.916-2.649-5.916-5.916c0-3.268,2.649-5.916,5.916-5.916c3.268,0,5.916,2.649,5.916,5.916
			C353.719,175.778,351.07,178.427,347.803,178.427z"
            />
          </g>
          <g>
            <path
              style="fill:#407BFF;"
              d="M333.734,202.241c0-0.388-0.02-0.772-0.053-1.152l2.258-1.254c-0.17-1.086-0.456-2.132-0.84-3.131
			l-2.578,0.043c-0.326-0.701-0.714-1.368-1.155-1.995l1.327-2.212c-0.682-0.842-1.451-1.611-2.293-2.293l-2.212,1.326
			c-0.627-0.441-1.293-0.828-1.995-1.155l0.043-2.578c-0.998-0.384-2.045-0.67-3.131-0.84l-1.254,2.258
			c-0.38-0.033-0.763-0.054-1.152-0.054s-0.772,0.02-1.152,0.054l-1.254-2.258c-1.086,0.17-2.133,0.456-3.131,0.84l0.043,2.578
			c-0.701,0.327-1.368,0.714-1.995,1.155l-2.212-1.326c-0.842,0.682-1.611,1.451-2.293,2.293l1.326,2.212
			c-0.441,0.627-0.828,1.294-1.155,1.995l-2.579-0.043c-0.384,0.998-0.67,2.045-0.84,3.131l2.258,1.254
			c-0.033,0.38-0.053,0.763-0.053,1.152c0,0.388,0.02,0.772,0.053,1.152l-2.258,1.254c0.17,1.086,0.456,2.133,0.84,3.131
			l2.579-0.043c0.326,0.701,0.714,1.368,1.155,1.995l-1.326,2.212c0.682,0.842,1.451,1.611,2.293,2.293l2.212-1.326
			c0.627,0.441,1.293,0.828,1.995,1.155l-0.043,2.578c0.998,0.384,2.045,0.67,3.131,0.84l1.254-2.258
			c0.38,0.033,0.763,0.053,1.152,0.053s0.772-0.02,1.152-0.053l1.254,2.258c1.086-0.17,2.133-0.456,3.131-0.84l-0.043-2.578
			c0.701-0.327,1.368-0.714,1.995-1.155l2.212,1.326c0.842-0.682,1.611-1.451,2.293-2.293l-1.327-2.212
			c0.441-0.627,0.828-1.294,1.155-1.995l2.578,0.043c0.384-0.998,0.67-2.045,0.84-3.131l-2.258-1.254
			C333.713,203.013,333.734,202.63,333.734,202.241z M320.698,207.379c-2.838,0-5.138-2.3-5.138-5.138
			c0-2.838,2.3-5.138,5.138-5.138s5.138,2.3,5.138,5.138C325.836,205.079,323.535,207.379,320.698,207.379z"
            />
            <path
              style="opacity:0.7;fill:#FFFFFF;"
              d="M333.734,202.241c0-0.388-0.02-0.772-0.053-1.152l2.258-1.254
			c-0.17-1.086-0.456-2.132-0.84-3.131l-2.578,0.043c-0.326-0.701-0.714-1.368-1.155-1.995l1.327-2.212
			c-0.682-0.842-1.451-1.611-2.293-2.293l-2.212,1.326c-0.627-0.441-1.293-0.828-1.995-1.155l0.043-2.578
			c-0.998-0.384-2.045-0.67-3.131-0.84l-1.254,2.258c-0.38-0.033-0.763-0.054-1.152-0.054s-0.772,0.02-1.152,0.054l-1.254-2.258
			c-1.086,0.17-2.133,0.456-3.131,0.84l0.043,2.578c-0.701,0.327-1.368,0.714-1.995,1.155l-2.212-1.326
			c-0.842,0.682-1.611,1.451-2.293,2.293l1.326,2.212c-0.441,0.627-0.828,1.294-1.155,1.995l-2.579-0.043
			c-0.384,0.998-0.67,2.045-0.84,3.131l2.258,1.254c-0.033,0.38-0.053,0.763-0.053,1.152c0,0.388,0.02,0.772,0.053,1.152
			l-2.258,1.254c0.17,1.086,0.456,2.133,0.84,3.131l2.579-0.043c0.326,0.701,0.714,1.368,1.155,1.995l-1.326,2.212
			c0.682,0.842,1.451,1.611,2.293,2.293l2.212-1.326c0.627,0.441,1.293,0.828,1.995,1.155l-0.043,2.578
			c0.998,0.384,2.045,0.67,3.131,0.84l1.254-2.258c0.38,0.033,0.763,0.053,1.152,0.053s0.772-0.02,1.152-0.053l1.254,2.258
			c1.086-0.17,2.133-0.456,3.131-0.84l-0.043-2.578c0.701-0.327,1.368-0.714,1.995-1.155l2.212,1.326
			c0.842-0.682,1.611-1.451,2.293-2.293l-1.327-2.212c0.441-0.627,0.828-1.294,1.155-1.995l2.578,0.043
			c0.384-0.998,0.67-2.045,0.84-3.131l-2.258-1.254C333.713,203.013,333.734,202.63,333.734,202.241z M320.698,207.379
			c-2.838,0-5.138-2.3-5.138-5.138c0-2.838,2.3-5.138,5.138-5.138s5.138,2.3,5.138,5.138
			C325.836,205.079,323.535,207.379,320.698,207.379z"
            />
          </g>
        </g>
        <g id="Speech_bubbles">
          <g>
            <path
              style="fill:#407BFF;"
              d="M296.967,144.26v5.239l-4.985,2.63c-0.864,0.456-0.864,1.693,0,2.149l4.985,2.63v5.239
			c0,1.169,0.948,2.117,2.117,2.117h17.885c1.169,0,2.117-0.948,2.117-2.117V144.26c0-1.169-0.948-2.117-2.117-2.117h-17.885
			C297.915,142.144,296.967,143.092,296.967,144.26z"
            />
            <g style="opacity:0.9;">
              <path
                style="fill:#FFFFFF;"
                d="M303.124,149.717c0.409,0,9.753,0,10.162,0c0.69,0,1.249-0.559,1.249-1.249
				c0-0.69-0.559-1.249-1.249-1.249c-0.505,0-9.657,0-10.162,0c-0.69,0-1.249,0.559-1.249,1.249
				C301.875,149.158,302.434,149.717,303.124,149.717z"
              />
              <path
                style="fill:#FFFFFF;"
                d="M313.286,151.954c-0.457,0-9.705,0-10.162,0c-0.69,0-1.249,0.559-1.249,1.249
				s0.559,1.249,1.249,1.249c0.457,0,9.705,0,10.162,0c0.69,0,1.249-0.559,1.249-1.249S313.976,151.954,313.286,151.954z"
              />
              <path
                style="fill:#FFFFFF;"
                d="M313.286,156.689c-0.533,0-9.629,0-10.162,0c-0.69,0-1.249,0.559-1.249,1.249
				c0,0.69,0.559,1.249,1.249,1.249c0.378,0,9.784,0,10.162,0c0.69,0,1.249-0.559,1.249-1.249
				C314.535,157.248,313.976,156.689,313.286,156.689z"
              />
            </g>
          </g>
          <g>
            <path
              style="fill:#407BFF;"
              d="M195.897,136.037h4.273l2.202,4.175c0.637,1.208,2.367,1.208,3.004,0l2.202-4.175h4.273
			c1.702,0,3.082-1.38,3.082-3.082v-15.954c0-1.702-1.38-3.082-3.082-3.082h-15.954c-1.702,0-3.082,1.38-3.082,3.082v15.954
			C192.815,134.657,194.195,136.037,195.897,136.037z"
            />
            <polygon
              style="opacity:0.9;fill:#FFFFFF;"
              points="203.885,118.687 198.956,121.571 198.956,129.988 202.623,129.988 
			202.623,124.338 205.146,124.338 205.146,129.988 208.814,129.988 208.814,121.571 		"
            />
          </g>
          <g>
            <path
              style="fill:#407BFF;"
              d="M243.8,364.79h-3.845l-2.455-4.655c-0.53-1.004-1.968-1.004-2.497,0l-2.455,4.655h-3.845
			c-1.939,0-3.51,1.572-3.51,3.51v15.098c0,1.939,1.571,3.51,3.51,3.51H243.8c1.938,0,3.51-1.571,3.51-3.51V368.3
			C247.31,366.361,245.738,364.79,243.8,364.79z"
            />
            <path
              style="opacity:0.9;fill:#FFFFFF;"
              d="M238.386,370.556c-2.13-2.13-5.595-2.13-7.725,0c-2.13,2.13-2.13,5.595,0,7.725
			c1.889,1.889,4.827,2.098,6.954,0.635c0.04,0.244,0.152,0.478,0.339,0.665l3.392,3.392c0.478,0.478,1.254,0.478,1.732,0
			c0.478-0.478,0.478-1.254,0-1.732l-3.392-3.392c-0.188-0.188-0.422-0.299-0.665-0.34
			C240.484,375.383,240.275,372.445,238.386,370.556z M237.231,377.125c-1.493,1.493-3.922,1.493-5.415,0
			c-1.493-1.493-1.493-3.923,0-5.416c1.493-1.493,3.923-1.493,5.415,0C238.724,373.203,238.724,375.632,237.231,377.125z"
            />
          </g>
        </g>
        <g id="Character_2_1_">
          <path
            style="fill:#EEC1BB;"
            d="M405.29,264.229c0,0-8.154,20.2-16.13,22.385c-7.976,2.185-20.014,0.41-20.014,0.41l-3.72,1.017
		l-3.646-0.542c-0.041-0.006-0.083-0.01-0.126-0.011l-4.119-0.095c-0.701-0.016-1.089-0.701-0.7-1.237l1.479-2.04
		c0.116-0.159,0.287-0.281,0.486-0.345l3.917-1.262c0.154-0.05,0.319-0.063,0.477-0.038l5.988,0.95c0,0,16.651-2.728,18.345-4.273
		c1.694-1.544,6.714-17.693,6.714-17.693L405.29,264.229z"
          />
          <path
            style="opacity:0.1;"
            d="M369.566,283.357c-0.246,0.041-0.384,0.064-0.384,0.064l-5.988-0.95
		c-0.158-0.025-0.323-0.012-0.477,0.038l-3.917,1.261c-0.199,0.064-0.371,0.186-0.486,0.346l-1.479,2.04
		c-0.389,0.536-0.001,1.221,0.7,1.237l4.119,0.095c0.042,0.001,0.084,0.004,0.125,0.011l2.452,0.365
		C366.051,287.072,367.86,285.42,369.566,283.357z"
          />
          <path
            style="fill:#407BFF;"
            d="M410.208,244.224c-5.921-1.943-14.251,2.505-17.442,21.148l12.293,3.587
		C405.06,268.959,418.672,247.002,410.208,244.224z"
          />
          <g>
            <path
              style="fill:#263238;"
              d="M375.768,219.864c0.008,0.612,0.33,1.107,0.732,1.103c0.401-0.004,0.746-0.53,0.723-1.124
			c-0.023-0.595-0.33-1.107-0.732-1.096C376.087,218.757,375.767,219.254,375.768,219.864z"
            />
            <path
              style="fill:#D4827D;"
              d="M378.194,220.47l-2.909,4.811c0,0,1.006,1.138,3.189,0.298L378.194,220.47z"
            />
            <path
              style="fill:#263238;"
              d="M378.741,216.026c-0.073,0.08-0.179,0.12-0.286,0.107c-0.799-0.138-1.615,0.116-2.194,0.684
			c-0.116,0.132-0.315,0.146-0.449,0.033c-0.131-0.118-0.144-0.32-0.03-0.454c0.717-0.735,1.749-1.072,2.761-0.9
			c0.176,0.024,0.298,0.186,0.275,0.362l0,0.001C378.808,215.92,378.781,215.978,378.741,216.026z"
            />
            <path
              style="fill:#EEC1BB;"
              d="M399.296,215.502c0.455,8.129,0.824,11.566-2.763,16.188c-5.732,4.951-15.495,5.742-18.369-2.095
			c-2.59-7.049-2.898-19.129,4.626-23.319c5.365-2.984,12.132-1.053,15.116,4.311C398.744,212.096,399.22,213.778,399.296,215.502z"
            />
            <polygon
              style="fill:#EEC1BB;"
              points="387.968,233.295 390.027,245.337 400.069,243.017 396.555,230.724 		"
            />
            <path
              style="fill:#263238;"
              d="M380.736,216.103c0.511-5.283-1.302,0.816-3.75-6.152c-2.448-6.968,9.092-12.633,10.936-11.334
			c1.845,1.299,0.786,3.297,6.888,4.42c6.102,1.123,5.843,7.048,5.843,7.048s3.51,1.628,2.261,9.168
			c-1.248,7.54-6.011,13.243-6.011,13.243s-2.309-2.381-5.963-0.182c0,0-1.797-1.006-3.566-3.648
			c-1.647-2.459-2.154-4.794-4.407-4.997C378.239,223.245,380.568,217.837,380.736,216.103z"
            />
            <path
              style="fill:#263238;"
              d="M399.992,211.014c0,0,4.981-3.063,2.777-6.273C400.565,201.53,401.629,208.267,399.992,211.014z"
            />
            <path
              style="fill:#FFC3BD;"
              d="M382.172,219.17c-1.111,1.238-1.682,2.868-1.589,4.529c0.141,2.202,2.296,2.594,3.999,1.391
			c1.544-1.089,3.309-3.543,2.326-5.474C385.926,217.685,383.548,217.671,382.172,219.17z"
            />
            <path
              style="opacity:0.1;"
              d="M388.162,234.43l0.818,4.781c-0.053-4.371,2.258-6.532,2.258-6.532
			C388.887,233.028,388.162,234.43,388.162,234.43z"
            />
          </g>
          <g>
            <rect
              x="314.698"
              y="265.514"
              style="fill:#407BFF;"
              width="2.871"
              height="16.934"
            />
            <rect
              x="314.698"
              y="265.514"
              style="opacity:0.7;fill:#FFFFFF;"
              width="2.871"
              height="16.934"
            />
          </g>
          <g>
            <path
              style="fill:#407BFF;"
              d="M297.845,231.894h-7.149c-2.679,0-4.851,2.172-4.851,4.851v33.12c0,2.679,2.172,4.851,4.851,4.851
			h7.149V231.894z"
            />
            <g>
              <path
                style="fill:#407BFF;"
                d="M341.57,274.716h-48.374c-2.679,0-4.851-2.172-4.851-4.851v-33.12
				c0-2.679,2.172-4.851,4.851-4.851h48.374c2.679,0,4.851,2.172,4.851,4.851v33.12C346.421,272.544,344.249,274.716,341.57,274.716
				z"
              />
              <path
                style="opacity:0.5;fill:#FFFFFF;"
                d="M341.57,274.716h-48.374c-2.679,0-4.851-2.172-4.851-4.851v-33.12
				c0-2.679,2.172-4.851,4.851-4.851h48.374c2.679,0,4.851,2.172,4.851,4.851v33.12C346.421,272.544,344.249,274.716,341.57,274.716
				z"
              />
            </g>
            <path
              style="fill:#263238;"
              d="M293.196,233.895c-1.572,0-2.851,1.278-2.851,2.851v33.12c0,1.572,1.279,2.851,2.851,2.851h48.375
			c1.572,0,2.851-1.278,2.851-2.851v-33.12c0-1.572-1.279-2.851-2.851-2.851H293.196z"
            />
          </g>
          <path
            style="fill:#407BFF;"
            d="M305.689,289.54c0-5.768,4.676-10.444,10.444-10.444c5.768,0,10.444,4.676,10.444,10.444H305.689z"
          />
          <g>
            <polygon
              style="fill:#407BFF;"
              points="330.344,285.976 309.455,285.976 309.455,289.372 351.233,289.372 351.233,287.976 		"
            />
            <polygon
              style="opacity:0.8;fill:#FFFFFF;"
              points="330.344,285.976 309.455,285.976 309.455,289.372 351.233,289.372 
			351.233,287.976 		"
            />
            <polygon
              style="fill:#407BFF;"
              points="330.344,289.372 309.455,289.372 309.455,285.976 330.344,287.976 		"
            />
            <polygon
              style="opacity:0.6;fill:#FFFFFF;"
              points="330.344,289.372 309.455,289.372 309.455,285.976 330.344,287.976 		"
            />
          </g>
          <g>
            <g>
              <path
                style="fill:#263238;"
                d="M376.404,406.083h-43.099c-3.616,0-6.547-2.931-6.547-6.547v-99.072
				c0-3.616,2.931-6.547,6.547-6.547h43.099c0.966,0,1.75,0.784,1.75,1.75l0,0c0,0.967-0.784,1.75-1.75,1.75h-43.099
				c-1.683,0-3.047,1.364-3.047,3.047v99.072c0,1.683,1.364,3.047,3.047,3.047h43.099c0.966,0,1.75,0.783,1.75,1.75l0,0
				C378.154,405.299,377.37,406.083,376.404,406.083z"
              />
            </g>
            <g style="opacity:0.5;">
              <path
                style="fill:#FFFFFF;"
                d="M376.404,406.083h-43.099c-3.616,0-6.547-2.931-6.547-6.547v-99.072
				c0-3.616,2.931-6.547,6.547-6.547h43.099c0.966,0,1.75,0.784,1.75,1.75l0,0c0,0.967-0.784,1.75-1.75,1.75h-43.099
				c-1.683,0-3.047,1.364-3.047,3.047v99.072c0,1.683,1.364,3.047,3.047,3.047h43.099c0.966,0,1.75,0.783,1.75,1.75l0,0
				C378.154,405.299,377.37,406.083,376.404,406.083z"
              />
            </g>
          </g>
          <g>
            <rect
              x="271.945"
              y="289.372"
              style="fill:#407BFF;"
              width="61.185"
              height="5.128"
            />
            <rect
              x="271.945"
              y="289.372"
              style="opacity:0.2;fill:#FFFFFF;"
              width="61.185"
              height="5.128"
            />
            <rect
              x="330.344"
              y="289.372"
              style="fill:#407BFF;"
              width="52.185"
              height="5.128"
            />
            <rect
              x="330.344"
              y="289.372"
              style="opacity:0.5;fill:#FFFFFF;"
              width="52.185"
              height="5.128"
            />
          </g>
          <g>
            <g>
              <polygon
                style="fill:#EEC1BB;"
                points="364.226,369.733 367.122,398.947 360.197,398.947 353.271,370.488 			"
              />
              <g>
                <path
                  style="fill:#263238;"
                  d="M359.548,397.04l8.831,0.151c0.333,0.006,0.618,0.241,0.685,0.567l1.474,7.212
					c0.135,0.639-0.273,1.266-0.911,1.402c-0.082,0.017-0.166,0.026-0.249,0.026c-3.183-0.11-6.011-0.332-9.974-0.395
					c-4.677-0.078-3.5,0.501-9,0.486c-3.314-0.013-3.856-3.789-2.448-3.951c6.444-0.7,7.145-2.63,9.664-4.889
					C358.165,397.217,358.852,397,359.548,397.04z"
                />
              </g>
              <polygon
                style="opacity:0.1;"
                points="366.366,391.321 364.226,369.733 353.271,370.488 358.341,391.321 			"
              />
            </g>
            <path
              style="fill:#263238;"
              d="M411.615,302.364l-28.386,1.018c0,0-38.195,13.352-39.267,23.663
			c-1.1,10.578,11.744,59.184,11.744,59.184h11.405c0,0-3.145-35.669-4.353-46.592c-1.208-10.923,39.792-7.303,46.909-16.622
			C416.218,314.437,411.615,302.364,411.615,302.364z"
            />
            <path
              style="opacity:0.2;"
              d="M411.615,302.364l-28.386,1.018c0,0-38.195,13.352-39.267,23.663
			c-1.1,10.578,11.744,59.184,11.744,59.184h11.405c0,0-3.145-35.669-4.353-46.592c-1.208-10.923,39.792-7.303,46.909-16.622
			C416.218,314.437,411.615,302.364,411.615,302.364z"
            />
            <polygon
              style="fill:#407BFF;"
              points="369.052,384.592 369.304,388.621 355.201,388.621 353.816,384.592 		"
            />
          </g>
          <g>
            <g>
              <polygon
                style="fill:#EEC1BB;"
                points="337.824,371.118 338.58,398.947 331.78,398.947 327.75,371.118 			"
              />
              <g>
                <path
                  style="fill:#263238;"
                  d="M330.837,397.04l8.832,0.151c0.333,0.006,0.617,0.241,0.685,0.567l1.474,7.212
					c0.135,0.639-0.273,1.266-0.911,1.402c-0.082,0.017-0.166,0.026-0.249,0.026c-3.183-0.11-6.011-0.332-9.974-0.395
					c-4.677-0.078-3.5,0.501-9.001,0.486c-3.314-0.013-3.856-3.789-2.448-3.951c6.444-0.7,7.145-2.63,9.664-4.889
					C329.454,397.217,330.141,397,330.837,397.04z"
                />
              </g>
              <path
                style="opacity:0.1;"
                d="M338.372,391.298l-0.548-20.18H327.75l2.925,20.202
				C332.866,391.327,335.884,391.313,338.372,391.298z"
              />
            </g>
            <path
              style="fill:#263238;"
              d="M399.274,304.714c0,0,2.356,21.575-19.599,22.582c-21.956,1.007-39.081,3.526-39.585,11.333
			c-0.504,7.807,0,47.599,0,47.599h-11.932c0,0-4.642-25.155-6.452-40.757c-1.758-15.153-4.967-26.138,10.578-31.271
			c16.526-5.457,45.908-10.5,45.908-10.5l21.157-1.337L399.274,304.714z"
            />
            <polygon
              style="fill:#407BFF;"
              points="341.853,384.592 341.098,388.621 328.002,388.621 326.616,384.592 		"
            />
          </g>
          <g>
            <path
              style="fill:#407BFF;"
              d="M404.038,242.805c0,0-0.003-4.869-9.831-4.306c-10.435,0.598-8.47,5.263-8.47,5.263
			L404.038,242.805z"
            />
            <path
              style="opacity:0.3;"
              d="M404.038,242.805c0,0-0.003-4.869-9.831-4.306c-10.435,0.598-8.47,5.263-8.47,5.263L404.038,242.805
			z"
            />
          </g>
          <path
            style="fill:#407BFF;"
            d="M413.163,264.602c-0.659,9.608-0.433,38.519-0.433,38.519s-20.973,1.378-37.48,0.842l-1.914-55.615
		c0-1.98,1.23-3.782,3.142-4.525c4.661-1.812,15.599-3.863,32.711-1.617C415.091,242.981,413.942,253.242,413.163,264.602z"
          />
          <path
            style="fill:#EEC1BB;"
            d="M376.707,264.229c0,0-8.154,20.2-16.13,22.385c-7.976,2.185-20.014,0.41-20.014,0.41l-3.72,1.017
		l-3.646-0.542c-0.041-0.006-0.083-0.01-0.126-0.011l-4.119-0.095c-0.701-0.016-1.089-0.701-0.7-1.237l1.479-2.04
		c0.116-0.159,0.287-0.281,0.486-0.345l3.917-1.262c0.154-0.05,0.319-0.063,0.477-0.038l5.988,0.95c0,0,16.651-2.728,18.345-4.273
		c1.694-1.544,6.714-17.693,6.714-17.693L376.707,264.229z"
          />
          <path
            style="opacity:0.1;"
            d="M376.496,254.833c0,0-1.263,1.503-2.809,3.686l0.554,16.098c6.128-2.704,8.558-18.488,8.558-18.488
		l-3.978-2.824L376.496,254.833z"
          />
          <path
            style="fill:#407BFF;"
            d="M381.626,244.224c-5.921-1.943-14.251,2.505-17.442,21.148l12.293,3.587
		C376.477,268.959,390.09,247.002,381.626,244.224z"
          />
          <g>
            <g>
              <rect
                x="380.206"
                y="337.77"
                style="fill:#263238;"
                width="9.25"
                height="27.113"
              />
              <rect
                x="380.206"
                y="337.77"
                style="opacity:0.6;fill:#FFFFFF;"
                width="9.25"
                height="27.113"
              />
              <g>
                <polygon
                  style="fill:#263238;"
                  points="390.892,389.444 390.892,354.995 378.771,354.995 378.771,389.444 351.179,393.272 
					351.179,397.099 384.831,394.229 418.483,397.099 418.483,393.272 				"
                />
              </g>
              <g>
                <circle
                  style="fill:#407BFF;"
                  cx="350.779"
                  cy="402.841"
                  r="3.648"
                />
                <circle
                  style="fill:#407BFF;"
                  cx="418.483"
                  cy="402.841"
                  r="3.648"
                />
              </g>
              <circle
                style="fill:#407BFF;"
                cx="384.831"
                cy="402.841"
                r="3.648"
              />
              <rect
                x="380.206"
                y="337.77"
                style="opacity:0.3;"
                width="9.25"
                height="6.699"
              />
            </g>
            <g>
              <path
                style="fill:#407BFF;"
                d="M421.401,276.321h-30.14c-4.909,0-9.011,3.737-9.468,8.625l-2.545,27.235
				c-0.58,6.204-5.627,11.04-11.849,11.356l-29.086,1.474c-3.536,0.179-6.311,3.099-6.311,6.639v0.614
				c0,3.041,2.466,5.507,5.507,5.507h39.349h7.974h41.267l4.77-51.055C431.39,281.138,427.002,276.321,421.401,276.321z"
              />
              <path
                style="opacity:0.3;fill:#FFFFFF;"
                d="M421.401,276.321h-30.14c-4.909,0-9.011,3.737-9.468,8.625l-2.545,27.235
				c-0.58,6.204-5.627,11.04-11.849,11.356l-29.086,1.474c-3.536,0.179-6.311,3.099-6.311,6.639v0.614
				c0,3.041,2.466,5.507,5.507,5.507h39.349h7.974h41.267l4.77-51.055C431.39,281.138,427.002,276.321,421.401,276.321z"
              />
              <path
                style="fill:#263238;"
                d="M354.365,330.753h-0.151c-1.103,0-1.998-0.894-1.998-1.998v-21.709h4.147v21.709
				C356.363,329.858,355.468,330.753,354.365,330.753z"
              />
              <path
                style="opacity:0.2;fill:#FFFFFF;"
                d="M354.365,330.753h-0.151c-1.103,0-1.998-0.894-1.998-1.998v-21.709h4.147v21.709
				C356.363,329.858,355.468,330.753,354.365,330.753z"
              />
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M372.232,300.769h-9.25v7.655h9.25c2.114,0,3.828-1.714,3.828-3.828
					C376.059,302.483,374.345,300.769,372.232,300.769z"
                />
                <path
                  style="opacity:0.5;fill:#FFFFFF;"
                  d="M372.232,300.769h-9.25v7.655h9.25c2.114,0,3.828-1.714,3.828-3.828
					C376.059,302.483,374.345,300.769,372.232,300.769z"
                />
                <path
                  style="fill:#407BFF;"
                  d="M368.544,308.424h-32.336c-2.037,0-3.688-1.651-3.688-3.688v-0.279
					c0-2.037,1.651-3.688,3.688-3.688h32.336c2.037,0,3.688,1.651,3.688,3.688v0.279
					C372.232,306.773,370.58,308.424,368.544,308.424z"
                />
                <path
                  style="opacity:0.3;fill:#FFFFFF;"
                  d="M368.544,308.424h-32.336c-2.037,0-3.688-1.651-3.688-3.688v-0.279
					c0-2.037,1.651-3.688,3.688-3.688h32.336c2.037,0,3.688,1.651,3.688,3.688v0.279
					C372.232,306.773,370.58,308.424,368.544,308.424z"
                />
              </g>
              <path
                style="fill:#407BFF;"
                d="M428.182,337.77h-44.308l4.936-52.824c0.457-4.888,4.559-8.625,9.468-8.625h30.14
				c5.601,0,9.989,4.817,9.468,10.394l-4.311,46.142C433.315,335.642,430.978,337.77,428.182,337.77z"
              />
              <path
                style="opacity:0.5;fill:#FFFFFF;"
                d="M428.182,337.77h-44.308l4.936-52.824c0.457-4.888,4.559-8.625,9.468-8.625h30.14
				c5.601,0,9.989,4.817,9.468,10.394l-4.311,46.142C433.315,335.642,430.978,337.77,428.182,337.77z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style="fill:#263238;"
                d="M324.674,406.083h-43.099c-3.616,0-6.547-2.931-6.547-6.547v-99.072
				c0-3.616,2.931-6.547,6.547-6.547h43.099c0.966,0,1.75,0.784,1.75,1.75l0,0c0,0.967-0.784,1.75-1.75,1.75h-43.099
				c-1.683,0-3.047,1.364-3.047,3.047v99.072c0,1.683,1.364,3.047,3.047,3.047h43.099c0.966,0,1.75,0.783,1.75,1.75l0,0
				C326.424,405.299,325.641,406.083,324.674,406.083z"
              />
            </g>
            <g style="opacity:0.5;">
              <path
                style="fill:#FFFFFF;"
                d="M324.674,406.083h-43.099c-3.616,0-6.547-2.931-6.547-6.547v-99.072
				c0-3.616,2.931-6.547,6.547-6.547h43.099c0.966,0,1.75,0.784,1.75,1.75l0,0c0,0.967-0.784,1.75-1.75,1.75h-43.099
				c-1.683,0-3.047,1.364-3.047,3.047v99.072c0,1.683,1.364,3.047,3.047,3.047h43.099c0.966,0,1.75,0.783,1.75,1.75l0,0
				C326.424,405.299,325.641,406.083,324.674,406.083z"
              />
            </g>
          </g>
          <g>
            <path
              style="fill:#FFFFFF;"
              d="M296.194,241.786l-3.035-2.014c-0.325-0.216-0.325-0.693,0-0.909l3.035-2.014
			c0.227-0.15,0.532-0.089,0.682,0.138l0,0c0.15,0.227,0.089,0.532-0.138,0.682l-2.483,1.648l2.483,1.648
			c0.226,0.15,0.288,0.456,0.138,0.682l0,0C296.725,241.874,296.42,241.936,296.194,241.786z"
            />
          </g>
          <g>
            <path
              style="fill:#FFFFFF;"
              d="M302.826,241.648L302.826,241.648c-0.15-0.227-0.089-0.532,0.138-0.682l2.483-1.648l-2.483-1.648
			c-0.227-0.15-0.288-0.456-0.138-0.682l0,0c0.15-0.226,0.456-0.288,0.682-0.138l3.034,2.014c0.325,0.216,0.325,0.693,0,0.909
			l-3.035,2.014C303.282,241.936,302.977,241.874,302.826,241.648z"
            />
          </g>
          <g>
            <path
              style="fill:#FFFFFF;"
              d="M298.721,242.958L298.721,242.958c-0.259-0.082-0.403-0.358-0.321-0.618l2.066-6.543
			c0.082-0.259,0.358-0.403,0.617-0.321l0,0c0.259,0.082,0.403,0.358,0.321,0.618l-2.066,6.543
			C299.256,242.896,298.98,243.04,298.721,242.958z"
            />
          </g>
          <g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M315.7,248h-22.266c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519H315.7
				c0.286,0,0.519,0.232,0.519,0.519l0,0C316.219,247.768,315.987,248,315.7,248z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M341.284,248h-22.266c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h22.266c0.287,0,0.519,0.232,0.519,0.519l0,0C341.803,247.768,341.571,248,341.284,248z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M306.157,252.583h-12.723c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h12.723c0.287,0,0.519,0.232,0.519,0.519l0,0C306.676,252.351,306.444,252.583,306.157,252.583z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M330.315,252.583h-20.841c-0.286,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h20.841c0.287,0,0.519,0.232,0.519,0.519l0,0C330.834,252.351,330.602,252.583,330.315,252.583z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M327.353,257.167h-33.918c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.286,0.232-0.519,0.519-0.519
				h33.918c0.287,0,0.519,0.232,0.519,0.519l0,0C327.871,256.934,327.639,257.167,327.353,257.167z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M337.752,257.167h-7.082c-0.286,0-0.519-0.232-0.519-0.519l0,0c0-0.286,0.232-0.519,0.519-0.519
				h7.082c0.286,0,0.519,0.232,0.519,0.519l0,0C338.271,256.934,338.039,257.167,337.752,257.167z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M309.27,261.75h-15.836c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h15.836c0.287,0,0.519,0.232,0.519,0.519l0,0C309.789,261.518,309.557,261.75,309.27,261.75z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M332.304,261.75h-19.717c-0.286,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h19.717c0.286,0,0.519,0.232,0.519,0.519l0,0C332.823,261.518,332.591,261.75,332.304,261.75z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M315.7,266.333h-22.266c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				H315.7c0.286,0,0.519,0.232,0.519,0.519l0,0C316.219,266.101,315.987,266.333,315.7,266.333z"
              />
            </g>
            <g>
              <path
                style="fill:#FFFFFF;"
                d="M334.726,266.333h-15.708c-0.287,0-0.519-0.232-0.519-0.519l0,0c0-0.287,0.232-0.519,0.519-0.519
				h15.708c0.287,0,0.519,0.232,0.519,0.519l0,0C335.245,266.101,335.012,266.333,334.726,266.333z"
              />
            </g>
          </g>
        </g>
        <g id="Character_1">
          <g>
            <path
              style="fill:#B97964;"
              d="M141.474,176.82c0.405,4.709-0.614,13.242-4.167,15.97c5.797,2.087,11.928,5.692,15.858,10.438
			c3.887-5.66-0.539-8.887-0.539-8.887c-5.01-1.783-4.464-5.569-3.176-9.059L141.474,176.82z"
            />
            <path
              style="fill:#263238;"
              d="M160.401,172.761c-0.057,0.588,0.219,1.099,0.607,1.137c0.388,0.038,0.746-0.419,0.802-1.007
			c0.057-0.588-0.22-1.105-0.607-1.143C160.816,171.71,160.471,172.166,160.401,172.761z"
            />
            <path
              style="fill:#263238;"
              d="M161.045,171.766l1.459-0.279C162.505,171.487,161.674,172.563,161.045,171.766z"
            />
            <path
              style="fill:#A24E3F;"
              d="M160.6,173.391c0.406,1.403,1.001,2.743,1.77,3.985c-0.653,0.382-1.442,0.456-2.155,0.203
			L160.6,173.391z"
            />
            <path
              style="fill:#263238;"
              d="M162.079,169.647c0.091-0.011,0.175-0.055,0.237-0.123c0.123-0.148,0.107-0.367-0.036-0.495
			c-0.834-0.758-1.984-1.065-3.086-0.824c-0.165,0.101-0.217,0.317-0.116,0.482c0.064,0.105,0.179,0.169,0.303,0.168
			c0.876-0.172,1.781,0.082,2.439,0.685C161.886,169.612,161.981,169.651,162.079,169.647z"
            />
            <path
              style="fill:#B97964;"
              d="M140.973,169.006c-0.231,7.315-0.482,10.426,2.78,14.501c4.911,6.132,13.797,4.789,16.172-2.331
			c2.141-6.406,2.163-17.322-4.563-20.867C148.635,156.765,141.209,161.671,140.973,169.006z"
            />
            <path
              style="fill:#263238;"
              d="M141.322,182.81c-7.194-1.225-5.128-5.759-5.128-5.759c-7.083-1.434-1.615-4.893-1.615-4.893
			c-5.891-2.538,0-4.814,0-4.814c-5.68-4.712,2.461-6.314,2.461-6.314c-2.009-7.351,4.969-4.335,4.969-4.335
			c3.806-6.314,7.079-0.283,7.079-0.283c6.665-9.047,8.286-1.413,8.286-1.413c6.802-1.791,4.193,5.368,4.193,5.368
			c5.603,1.505,1.205,4.338,1.205,4.338c2.156,2.733-5.654,3.204-5.654,3.204s3.717,5.957-0.033,7.649l-9.458,7.251
			C147.627,182.81,143.283,181,141.322,182.81z"
            />
            <path
              style="fill:#407BFF;"
              d="M148.388,176.909c0,0,4.952-11.972,1.092-22.242l3.303-1.131
			C152.783,153.536,157.093,168.428,148.388,176.909z"
            />
            <path
              style="fill:#B97964;"
              d="M153.375,175.595c0.828,1.718,1.141,2.465,2.324,3.035c1.035,0.582,2.347,0.214,2.928-0.821
			c0.266-0.473,0.343-1.029,0.216-1.557c-0.311-1.722-1.636-4.253-3.465-4.306C153.551,171.893,152.521,173.892,153.375,175.595z"
            />
            <g>
              <path
                style="fill:#B97964;"
                d="M148.214,200.572c0,0,6.765,4.968,9.517,6.967l19.586-9.241l0,0
				c-0.054-2.223,1.257-4.252,3.306-5.116l1.393-0.588l2.954-1.314c1.119-0.498,2.419-0.361,3.41,0.36l0,0
				c1.172,0.852,1.681,2.349,1.273,3.739l0,0c-0.84,2.858-3.116,5.068-5.997,5.824l-2.857,0.749c0,0-16.805,12.751-22.782,13.085
				c-5.977,0.333-14.788-5.588-14.788-5.588L148.214,200.572z"
              />
              <path
                style="fill:#263238;"
                d="M131.93,192.14c5.172-0.022,10.949,3.223,20.047,9.118c0,0-1.72,6.47-7.011,11.084
				c0,0-4.992-1.344-11.634-7.597C125.294,197.18,128.759,192.153,131.93,192.14z"
              />
            </g>
            <g>
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M230.133,207.123h-48.995c-1.778,0-3.093-1.441-2.937-3.219l3.691-42.188
					c0.156-1.778,1.723-3.219,3.5-3.219h48.995c1.778,0,3.093,1.441,2.937,3.219l-3.691,42.188
					C233.477,205.682,231.91,207.123,230.133,207.123z"
                />
                <path
                  style="opacity:0.4;fill:#FFFFFF;"
                  d="M230.133,207.123h-48.995c-1.778,0-3.093-1.441-2.937-3.219l3.691-42.188
					c0.156-1.778,1.723-3.219,3.5-3.219h48.995c1.778,0,3.093,1.441,2.937,3.219l-3.691,42.188
					C233.477,205.682,231.91,207.123,230.133,207.123z"
                />
              </g>
              <path
                style="opacity:0.7;fill:#FFFFFF;"
                d="M230.308,205.122c0.672,0,1.267-0.546,1.325-1.218l3.691-42.189
				c0.059-0.672-0.44-1.219-1.112-1.219h-48.995c-0.672,0-1.267,0.547-1.325,1.219l-3.691,42.189
				c-0.059,0.672,0.44,1.218,1.112,1.218H230.308z"
              />
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M196.257,180.316l-16.019,23.168l-0.037,0.42c-0.059,0.672,0.44,1.218,1.112,1.218h27.755
					L196.257,180.316z"
                />
                <path
                  style="opacity:0.3;fill:#FFFFFF;"
                  d="M196.257,180.316l-16.019,23.168l-0.037,0.42c-0.059,0.672,0.44,1.218,1.112,1.218
					h27.755L196.257,180.316z"
                />
              </g>
              <path
                style="fill:#407BFF;"
                d="M231.775,202.286l-13.413-25.468l-19.86,28.305h31.807c0.672,0,1.267-0.546,1.325-1.218
				L231.775,202.286z"
              />
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M213.183,172.315c-0.218,2.486-2.41,4.502-4.896,4.502c-2.486,0-4.326-2.016-4.108-4.502
					c0.217-2.487,2.41-4.502,4.896-4.502C211.561,167.813,213.4,169.829,213.183,172.315z"
                />
                <path
                  style="opacity:0.4;fill:#FFFFFF;"
                  d="M213.183,172.315c-0.218,2.486-2.41,4.502-4.896,4.502
					c-2.486,0-4.326-2.016-4.108-4.502c0.217-2.487,2.41-4.502,4.896-4.502C211.561,167.813,213.4,169.829,213.183,172.315z"
                />
              </g>
            </g>
            <g>
              <polygon
                style="fill:#B97964;"
                points="90.896,377.371 85.921,392.132 93.55,395.246 99.521,381.625 			"
              />
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M96.289,394.877c-0.08-0.045-0.125-0.132-0.116-0.222c0.011-0.091,0.078-0.165,0.168-0.186
					c0.159-0.036,3.762-0.84,5.104,0.016c0.251,0.155,0.432,0.402,0.505,0.689c0.114,0.328,0.009,0.693-0.263,0.91
					c-0.507,0.357-1.697,0.209-2.788-0.128C97.998,395.672,97.126,395.311,96.289,394.877z M97.049,394.806
					c1.572,0.707,3.791,1.376,4.374,0.931c0.081-0.053,0.174-0.161,0.099-0.467c-0.037-0.18-0.154-0.334-0.318-0.417
					c-0.118-0.076-0.247-0.135-0.382-0.174C99.567,394.482,98.287,394.525,97.049,394.806z"
                />
                <path
                  style="fill:#407BFF;"
                  d="M96.183,394.831c-0.05-0.06-0.064-0.143-0.035-0.217c0.046-0.106,1.011-2.402,2.413-2.976
					c0.405-0.172,0.863-0.171,1.267,0.002c0.555,0.234,0.645,0.579,0.592,0.824c-0.118,1.045-2.816,2.298-4.009,2.439
					c-0.031,0.005-0.063-0.002-0.089-0.019C96.271,394.884,96.221,394.865,96.183,394.831z M99.567,391.991
					c-0.262-0.084-0.547-0.07-0.799,0.04c-0.889,0.554-1.586,1.368-1.997,2.332c1.287-0.308,3.13-1.37,3.26-1.982
					c0.001-0.058,0.023-0.216-0.341-0.363L99.567,391.991L99.567,391.991z"
                />
                <path
                  style="fill:#263238;"
                  d="M95.772,393.371l-9.585-2.959c-0.361-0.112-0.752,0.042-0.939,0.37l-4.14,7.263
					c-0.372,0.642-0.153,1.463,0.488,1.835c0.082,0.048,0.169,0.087,0.26,0.116c3.474,1.007,6.604,1.766,10.904,3.099
					c5.076,1.569,3.6,1.778,9.542,3.706c3.581,1.158,5.501-2.727,4.039-3.398c-6.707-3.033-6.781-5.363-8.702-8.692
					C97.202,394.05,96.537,393.573,95.772,393.371z"
                />
              </g>
              <polygon
                style="opacity:0.2;fill:#263238;"
                points="88.511,384.448 96.511,388.492 99.521,381.625 90.896,377.371 			"
              />
            </g>
            <path
              style="fill:#407BFF;"
              d="M124.723,240.85c0,0-10.751,8.215-8.448,33.93c0.213,2.379,0.982,4.87,0.993,7.595
			c0.007,1.806-0.497,3.076-1.089,7.144c-0.862,5.925-2.444,27.997-4.107,30.867c-1.647,2.841-2.35,2.367-7.042,10.284
			c-5.048,8.517-11.498,23.458-16.524,48.786c0,0,4.366,3.41,11.235,4.704c0,0,27.474-43.318,33.314-59.446
			c5.225-14.43,11.452-60.48,11.452-60.48l0.503-19.966L124.723,240.85z"
            />
            <path
              style="opacity:0.1;"
              d="M136.484,274.78h-7.785c0,0-2.559,24.476,5.899,44.902c0.884-3.279,1.777-7.214,2.645-11.458
			l3.365-21.563c-0.478-3.643-0.853-6.419-0.908-6.604C139.53,279.492,136.484,274.78,136.484,274.78z"
            />
            <g>
              <polygon
                style="fill:#B97964;"
                points="135.199,383.31 135.251,398.998 144.102,398.406 145.46,383.31 			"
              />
              <g>
                <path
                  style="fill:#407BFF;"
                  d="M145.122,398.144c-0.09-0.016-0.161-0.084-0.182-0.172c-0.02-0.09,0.019-0.182,0.097-0.23
					c0.138-0.086,3.277-2.03,4.825-1.664c0.289,0.064,0.541,0.238,0.703,0.485c0.216,0.272,0.236,0.651,0.05,0.945
					c-0.361,0.504-1.533,0.755-2.675,0.796C146.998,398.333,146.055,398.279,145.122,398.144z M145.816,397.827
					c1.716,0.15,4.032,0.053,4.437-0.56c0.059-0.076,0.111-0.209-0.06-0.474c-0.094-0.158-0.255-0.264-0.438-0.289
					c-0.137-0.033-0.277-0.047-0.418-0.039C148.088,396.693,146.893,397.155,145.816,397.827z"
                />
                <path
                  style="fill:#407BFF;"
                  d="M145.006,398.135c-0.067-0.04-0.107-0.115-0.105-0.193c0.008-0.116,0.165-2.601,1.3-3.605
					c0.326-0.296,0.759-0.445,1.198-0.415c0.601,0.039,0.799,0.335,0.831,0.583c0.233,1.026-1.903,3.096-2.984,3.622
					c-0.028,0.015-0.06,0.019-0.09,0.011C145.107,398.157,145.054,398.155,145.006,398.135z M147.268,394.34
					c-0.275,0.007-0.539,0.114-0.742,0.3c-0.657,0.816-1.047,1.814-1.119,2.859c1.114-0.714,2.506-2.323,2.427-2.944
					c-0.018-0.055-0.05-0.212-0.442-0.231L147.268,394.34L147.268,394.34z"
                />
                <path
                  style="fill:#263238;"
                  d="M144.138,396.892l-10.024,0.358c-0.378,0.013-0.696,0.287-0.765,0.659l-1.521,8.22
					c-0.14,0.728,0.337,1.432,1.065,1.572c0.093,0.018,0.188,0.026,0.284,0.024c3.611-0.192,6.818-0.505,11.317-0.659
					c5.309-0.188,3.985,0.495,10.23,0.361c3.763-0.085,4.298-4.384,2.696-4.538c-7.331-0.658-8.168-2.834-11.077-5.346
					C145.712,397.063,144.928,396.831,144.138,396.892z"
                />
              </g>
              <polygon
                style="opacity:0.2;fill:#263238;"
                points="135.226,391.425 144.748,391.226 145.46,383.31 135.199,383.31 			"
              />
            </g>
            <path
              style="fill:#407BFF;"
              d="M130.707,237.971c0,0-10.725,14.208-2.781,42.033l8.969,40.583c0,0-8.536,18.464-4.03,67.163
			c0,0,6.778,1.085,13.245-0.296c0,0,9.987-51.434,11.507-68.808c1.673-19.134,0.671-76.827,0.671-76.827L130.707,237.971z"
            />
            <g>
              <path
                style="fill:#263238;"
                d="M137.192,191.942c0,0-1.662-7.104,8.994-3.996c10.656,3.108,9.768,9.176,9.768,9.176
				L137.192,191.942z"
              />
              <path
                style="opacity:0.4;"
                d="M137.192,191.942c0,0-1.662-7.104,8.994-3.996c10.656,3.108,9.768,9.176,9.768,9.176
				L137.192,191.942z"
              />
            </g>
            <path
              style="fill:#263238;"
              d="M163.632,209.7c0,2.49-1.05,6.09-1.99,10.12c-0.46,1.91-0.88,3.9-1.16,5.92
			c-1.51,11.01-2,22.59-2,22.59s-17.42,4.89-34.34-6.81c0,0,4.19-3.55,3.33-28.14c-0.39-11.08-4.39-24.25,13.92-22.18
			c15.46,1.75,20.66,6.55,21.91,14.06c0.01,0.09,0.03,0.19,0.05,0.29c0.03,0.23,0.06,0.46,0.09,0.7
			C163.572,207.34,163.632,208.5,163.632,209.7z"
            />
            <path
              style="opacity:0.4;"
              d="M163.632,209.7c0,2.49-1.05,6.09-1.99,10.12c-4.18-5.03-8.37-14.45-8.37-14.45l3.45-5.08l6.08,2.83
			c0.21,0.69,0.37,1.4,0.5,2.14c0.01,0.09,0.03,0.19,0.05,0.29c0.03,0.23,0.06,0.46,0.09,0.7
			C163.572,207.34,163.632,208.5,163.632,209.7z"
            />
            <path
              style="fill:#B97964;"
              d="M169.947,205.356c0,0,5.95,5.921,8.373,8.307l20.744-6.22l0,0c0.278-2.206,1.877-4.017,4.031-4.566
			l1.465-0.374l3.117-0.859c1.181-0.326,2.446,0.004,3.318,0.864l0,0c1.032,1.017,1.313,2.573,0.702,3.887l0,0
			c-1.256,2.701-3.836,4.548-6.798,4.865l-2.936,0.315c0,0-18.516,10.106-24.476,9.545c-5.96-0.561-13.79-7.728-13.79-7.728
			L169.947,205.356z"
            />
            <path
              style="fill:#263238;"
              d="M155.101,194.592c5.117,0.749,10.346,4.818,18.465,12.003c0,0-2.664,6.142-8.584,9.916
			c0,0-4.736-2.072-10.373-9.245C147.789,198.587,151.964,194.133,155.101,194.592z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
